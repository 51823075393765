<template>
  <TransitionRoot appear :show="!!dialog" as="div">
    <Dialog as="div" @close="closeDialog()" class="relative z-60">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/60" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-md transform rounded-md bg-white p-4 text-left align-middle shadow-xl transition-all overflow-visible"
            >
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-slate-900 relative flex flex-row gap-4"
                v-if="dialog?.title"
              >
                <span v-html="dialog?.title"></span>
              </DialogTitle>
              <button
                v-if="showCloseButton"
                type="button"
                class="-m-2 p-2 text-white absolute -bottom-10 right-2 flex flex-row items-center gap-2 rounded-lg bg-gray-800/40"
                @click="closeDialog()"
              >
                <span>Fechar</span>
                <XIcon class="h-4 w-4" aria-hidden="true" />
              </button>
              <div
                class="mt-2 whitespace-pre-wrap"
                v-html="dialog?.message"
                v-if="dialog?.message"
              ></div>
              <div class="whitespace-pre-wrap" v-if="dialog?.specialContents?.saleCondition">
                <SaleConditionRasgaPreco
                  v-if="
                    dialog?.specialContents?.saleCondition?.selling_method?.settings?.rasga_preco
                  "
                  :saleCondition="dialog?.specialContents?.saleCondition"
                  image-res="adapt"
                  image-loading="eager"
                  title-size="text-lg !text-gray-800"
                />

                <SaleCondition
                  v-else
                  :saleCondition="dialog?.specialContents?.saleCondition"
                  image-res="adapt"
                  image-loading="eager"
                  title-size="text-lg !text-gray-800"
                >
                  <AddToCart :saleConditionId="dialog?.specialContents?.saleCondition.id" />
                </SaleCondition>
              </div>

              <div
                :class="[
                  'mt-4 flex flex-row',
                  dialog?.buttons.length === 1 ? 'justify-end' : 'justify-between',
                ]"
                v-if="dialog?.buttons && dialog?.buttons.length > 0"
              >
                <MaraButton
                  v-for="button in dialog?.buttons"
                  :variant="button.type"
                  @click="buttonClick(button)"
                  :autofocus="button.isFocused ?? false"
                  :left-icon="button.icon"
                  ><span v-html="button.label"></span>
                </MaraButton>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import AddToCart from '@/components/AddToCart.vue'
import MaraButton from '&/components/MaraButton.vue'
import SaleCondition from '@/components/SaleCondition.vue'
import SaleConditionRasgaPreco from '@/components/SaleConditionRasgaPreco.vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { dynamicDialog, dynamicDialogFunctionContext } from '@/services/dynamicDialog'
import { ref, watch } from 'vue'
import { type Button, funNames } from '@/services/orders/can-buy'
import { useStore } from '@nanostores/vue'
import { XIcon } from '@heroicons/vue/outline'

const dialog = useStore(dynamicDialog)
const showCloseButton = ref(true)

watch(dialog, (newValue) => {
  showCloseButton.value = true
  if (newValue && newValue.buttons) {
    newValue.buttons.forEach((button: Button) => {
      if (button.funName === 'closeDialog') {
        showCloseButton.value = false
      }
    })
  }
})

async function buttonClick(button: Button) {
  if (button.funName && button.funName in funNames) {
    await funNames[button.funName]()
  }

  if (button.url) {
    closeDialog()
    window.location.hash = ''
    window.location.href = button.url ?? ''
  }
}

function closeDialog() {
  const dialog = dynamicDialog.get()
  if (dialog?.specialContents?.saleCondition.id) {
    const currentUrl = new URL(window.location.href)
    currentUrl.searchParams.delete('p')
    window.history.pushState(
      {
        path: currentUrl.toString(),
      },
      '',
      currentUrl.toString(),
    )
  }

  dynamicDialog.set(undefined)
  dynamicDialogFunctionContext.set(undefined)
}
</script>
